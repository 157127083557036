<template>
  <v-main app>
    <v-card
      flat
      outlined
      style="overflow-y: auto; max-width:800px; margin:auto;"
    >
    <v-card-title>Manage Marketing Preferences</v-card-title>
      <v-card-text v-if="marketingPreference" class="fill-height d-flex flex-column" style="overflow-y: auto">
        <h3 class="mb-4">
          We send occasional emails to our customers to keep them up to date with things like promotions, products and services.
        </h3>
        <h3 class="mb-4">
          If you no longer want to receive these emails, please click the unsubscribe button below. NB we will still send you emails related to your account and business orders.
        </h3>
      </v-card-text>
      <v-card-text v-if="!marketingPreference" class="fill-height d-flex flex-column" style="overflow-y: auto">
        <h3 class="mb-4">
          You have unsubscribed from marketing emails.
        </h3>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-text>
        <h3 class="mb-4">
          If you have any questions, don't hesitate to get in touch.
        </h3>
        <div>
          <v-icon class="mr-4" color="#018e4f"> mdi-phone </v-icon>
          +44 (0) 131 553 2772
        </div>
        <div class="mt-4">
          <v-icon class="mr-4" color="#018e4f"> mdi-email </v-icon>
          <a href="mailto://sales@doricmor.com">sales@doricmor.com</a>
        </div>
      </v-card-text>
      <v-card-actions v-if="marketingPreference" class="pb-0">
        <v-btn
          x-large
          block
          color="success"
          class="mb-4"
          @click.stop="submit"
        >
          Unsubscribe
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-main>
</template>

<script>
import UserApi from '../../api/user.js'
export default {
  data: () => ({
    marketingPreference: true,
    submitted: false,
    valid: false,
  }),
  computed: {
    token () { return this.$router.currentRoute.params.token }
  },
  async created() {
    UserApi.getSubscriptionDetails(this.token).then((subscription) => {
      this.marketingPreference = subscription.data.marketingPreference;
    });
  },
  methods: {
    submit() {
      UserApi.unsubscribeMarketingEmails(this.token).then((subscription) => {
        this.marketingPreference = subscription.data.marketingPreference;
      });
    },
  },
};
</script>
